.user-billing-info__wrapper {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  padding: 10px 0;
  background: #f1f3f5;
}

.user-billing-info__content {
  width: 100%;
  height: 80%;
}

.user-billing-info__text-wrapper {
  width: 75%;
  height: 100%;
  padding-left: 20px;
  border-right: 1px solid #dce0e4;
  /* background: royalblue; */
}

.user-billing-info__text-wrapper h3 {
  margin: unset;
  margin-bottom: 10px;
  font-size: 1.3em;
  font-weight: 700;
}

.user-billing-info__text-wrapper h4 {
  margin: unset;
  margin-bottom: 5px;
  font-size: 0.9em;
  font-weight: 600;
  color: #464646;
  margin-bottom: 10px;
}

.user-billing-info__text-wrapper h5 {
  font-size: 0.8em;
  font-weight: 400;
  color: rgb(92, 92, 92);
}

.user-billing-info__btn-wrapper {
  width: 25%;
  height: 100%;
  /* background: rosybrown; */
}

.user-billing-info__btn-wrapper button {
  all: unset;
  width: 100%;
  height: 100%;
  font-size: 0.85em;
  text-align: center;
  cursor: pointer;
  color: #016deb;
  transition: background-color 0.2s;
  /* background: violet; */
}

/* SMALL SIZE BROWSER */
@media (min-width: 481px) and (max-width: 767px) {
}

/* MOBILES */
@media screen and (max-device-width: 480px) {
  /* .user-billing-info__wrapper {
    height: 400px;
    padding: 20px 0;
  }
  .user-billing-info__text-wrapper h3 {
    margin-bottom: 15px;
    font-size: 3.3em;
  }

  .user-billing-info__text-wrapper h4 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  .user-billing-info__text-wrapper h5 {
    font-size: 2em;
  }
  .user-billing-info__btn-wrapper button {
    font-size: 2em;
  } */
}
