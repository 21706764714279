html,
body {
  position: relative;
  margin: unset !important;
  padding: unset !important;
  font-family: "Montserrat", sans-serif;
}

.app-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 300px; /* Footer height */
}

/* SMALL SIZE BROWSER */
@media (min-width: 481px) and (max-width: 767px) {
  .app-container {
    padding-bottom: 500px; /* Footer height */
  }
}

/* MOBILES */
@media screen and (max-device-width: 480px) {
  /* Footer height */
  .app-container {
    padding-bottom: 550px;
  }
}
