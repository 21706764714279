.nb-menu-wrapper {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
  background-color: black;
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
}

.nb-menu-close-btn {
  width: 50px;
  height: 50px;
  background-color: lightgray;
  cursor: pointer;
}

.nb-menu-close-btn img {
  width: 20px;
  height: 20px;
}

/* SMALL SIZE BROWSER */
@media (max-width: 868px) {
  li {
    width: 100%;
    padding: 20px;
  }
}

/* MOBILES */
@media screen and (max-device-width: 480px) {
  /* li {
    width: 100%;
    padding: 40px;
  }

  .link {
    font-size: 3em;
  }

  .nb-menu-close-btn {
    width: 100px;
    height: 100px;
  }

  .nb-menu-close-btn img {
    width: 40px;
    height: 40px;
  } */
}
