.gift-aid-section-wrapper {
  padding-bottom: 50px;
}

.gift-aid-section-content {
  margin-top: 70px;
  width: 100%;
  max-width: 1000px;
}

.gift-aid-section-content h2 {
  width: 70%;
  margin-bottom: 30px;
  text-align: center;
  font-family: "Nunito", sans-serif;
}

.gift-aid-section-content h1 {
  font-weight: 800;
  letter-spacing: 0.05em;
  font-size: 45px;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.gift-aid-section-content p {
  font-weight: 400;
  letter-spacing: 0.05em;
  font-size: 15px;
  max-width: 80%;
  text-align: center;
  margin-bottom: 30px;
  font-family: "Nunito", sans-serif;
}

.gift-aid-section-content button {
  background: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-top: 50px;
  width: 300px;
  height: 60px;
  text-decoration: none;
  color: black;
  border: 2px solid black;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.gift-aid-section-content button:hover {
  background-color: black;
  color: white;
}

/* MOBILE */
@media (min-width: 320px) and (max-device-width: 480px) {
}
