.footer-wrapper {
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  width: 100vw;
  height: 300px;
  padding: 50px 20px 10px 20px;
  background-color: #202020;
}

.footer-content {
  width: 95%;
  max-width: 1000px;
  height: 100%;
}

.footer-content-top {
  width: 100%;
  height: 90%;
}

.footer-logo-wrapper {
  width: 400px;
  height: 100%;
}

.footer-logo-wrapper h1 {
  margin: unset;
  text-align: center;
  font-size: 1.8em;
  font-weight: 700;
  letter-spacing: 0.25em;
  color: white;
  margin-bottom: 10px;
}

.footer-logo-wrapper p {
  font-size: 0.8em;
  font-weight: 400;
  letter-spacing: 0.5em;
  color: white;
}

.footer-logos-wrapper {
  width: 100%;
  height: 50px;
  margin-top: 20px;
}

.footer-logos-wrapper img {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.footer-links-wrapper {
  height: 100%;
}

.footer-link-group {
  width: 300px;
  height: 100%;
}

.footer-links-wrapper h3 {
  margin: unset;
  font-weight: 400;
  font-size: 1em;
  color: white;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
}

.footer-link {
  text-decoration: none;
  color: rgb(160, 160, 160);
  padding-bottom: 10px;
}

.footer-link:hover {
  color: white !important;
}

.footer-content-bottom {
  box-sizing: border-box;
  width: 100%;
  border-top: 0.5px solid rgb(160, 160, 160);
  padding-top: 20px;
}

.footer-content-bottom p {
  text-align: center;
  box-sizing: border-box;
  color: rgb(160, 160, 160);
  font-size: 0.8em;
  margin-bottom: 10px;
}

/* SMALL SIZE BROWSER */
@media (min-width: 768px) and (max-width: 1165px) {
  .footer-logo-wrapper h1 {
    font-size: 1.2em;
  }

  .footer-logo-wrapper p {
    font-size: 0.7em;
  }

  .footer-link-group {
    width: 200px;
  }
}

/* SMALL SIZE BROWSER */
@media (min-width: 481px) and (max-width: 767px) {
  .footer-wrapper {
    height: 500px;
  }

  .footer-content-top {
    align-items: center;
    flex-direction: column;
  }

  .footer-links-wrapper {
    align-items: flex-start;
    width: 100%;
  }

  .footer-link-group {
    align-items: flex-start;
  }
}

/* MOBILES */
@media screen and (max-device-width: 480px) {
  .footer-wrapper {
    height: 560px;
    padding: unset;
    padding-top: 10px;
  }
  .footer-content-top {
    align-items: center;
    flex-direction: column;
  }
  .footer-logo-wrapper {
    width: 100%;
    justify-content: center;
  }
  .footer-logo-wrapper h1 {
    font-size: 1.2em;
  }
  .footer-links-wrapper {
    margin-bottom: 5px;
  }

  .footer-link-group {
    margin: 20px 0;
  }
}
