.gift-aid-form-wrapper {
  max-width: 800px;
  width: 95%;
  min-height: 400px;
  margin-top: 50px;
  /* background: lightblue; */
}

.gift-aid-form-content {
  width: 100%;
}

.gift-aid-form-name-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  /* background: lightgoldenrodyellow; */
}

.gift-aid-form-name-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
}

.gift-aid-form-name-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: aquamarine; */
}

.gift-aid-form-consent-input-wrapper {
  width: 100%;
  display: flex;
  /* background: lightblue; */
}

.gift-aid-form-consent-input-wrapper input {
  text-align: justify;
  margin-top: 3px;
}

.gift-aid-form-consent-input-wrapper p {
  text-align: justify;
  margin-left: 10px;
  font-family: "Nunito", sans-serif;
  font-size: 10px;
  font-style: italic;
}

@media (max-width: 700px) {
  .gift-aid-form-wrapper {
    max-width: 100%;
  }
  .gift-aid-form-name-input-wrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
    /* background: aquamarine; */
  }

  .gift-aid-form-name-input-wrapper .gap-textinput-wrapper {
    width: 100% !important;
  }

  .gift-aid-form-name-input-wrapper > * {
    margin-right: unset !important;
  }
}

/* ************************************************ TABLETS ************************************************ */

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ************************************************ MOBILES ************************************************ */

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-device-width: 480px) {
  /* .gift-aid-form-wrapper {
    max-width: 100%;
  }
  .gift-aid-form-name-label-wrapper {
    height: 120px;
    margin-top: 50px;
  }
  .gift-aid-form-name-label-wrapper label {
    font-size: 2.3em;
    font-weight: 500;
  }
  .gift-aid-form-name-input-wrapper {
    justify-content: center;
    flex-wrap: wrap;
  }
  .gift-aid-form-name-input-wrapper .gap-textinput-wrapper {
    width: 100% !important;
    height: 150px !important;
  }
  .gap-textinput-wrapper div {
    height: 130px !important;
    border: 3px solid rgb(171, 171, 171) !important;
  }
  .gap-textinput-wrapper div input {
    font-size: 2.5em !important;
  }
  .gift-aid-form-consent-input-wrapper input {
    width: 50px;
    height: 50px;
  }
  .gift-aid-form-consent-input-wrapper p {
    margin-left: 15px;
    font-size: 2.2em;
    width: 100% !important;
    text-align: unset;
  } */
}
