.language-dropdown {
  box-sizing: border-box;
  position: absolute;
  min-width: 70px;
  top: 61px;
  right: 20px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid rgb(225, 225, 225);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.language-dropdown-row {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 9px;
  display: flex;
  align-items: center;
}
.language-dropdown-row:hover {
  background-color: rgb(238, 238, 238);
}

.language-dropdown-row p {
  font-weight: 500;
  margin-left: 10px;
}

.language-dropdown-row-disable {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(247, 253, 255, 0.795); */
  z-index: 2;
}

.language-dropdown-row-disable:hover {
  cursor: not-allowed;
  /* background-color: rgba(247, 253, 255, 0.795); */
}

.language-dropdown-row-disable-text {
  background-color: #f4b822;
  padding: 2px 4px;
  border-radius: 2px;
  color: white;
  font-weight: 500 !important;
  font-size: 0.7em !important;
  margin-right: 10px !important;
}

/* SMALL SIZE BROWSER */
@media (min-width: 481px) and (max-width: 868px) {
  .language-dropdown {
    right: 65px;
  }
}

/* MOBILES */
@media screen and (max-device-width: 480px) {
  .language-dropdown {
    right: 65px;
  }
}
