.cop-status-card-content {
  box-sizing: border-box;
  width: 300px;
  height: 350px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
}

.cop-status-card-content img {
  height: 100px;
  width: 100px;
}

.cop-status-card-content h3 {
  text-align: center;
  margin-bottom: 10px;
}

.cop-status-card-content h4 {
  text-align: center;
  margin-bottom: 10px;
}

.cop-status-card-content p {
  text-align: center;
  color: #747474;
  font-size: 0.85em;
}

/* SMALL SIZE BROWSER */
@media (min-width: 481px) and (max-width: 767px) {
}

/* MOBILES */
@media screen and (max-device-width: 480px) {
}
